export const airports = [
  "Aeropuerto Angel Adami (ASI - SUAA)",
  "Aeropuerto de Carrasco (CRR -SUMU)",
  "Aeropuerto Laguna del Sauce (PDP - SULS)",
  "Aeropuerto el Jaguel Punta del Este (SUPE)",
  "Aeropuerto de Colonia (SUCA)",
  "Aeropuerto San Fernando ( FDO - SADF)",
  "Aeropuerto de Ezeiza (EZE - SAEZ )",
  "Aeroparque (AEP - SABE)",
  "Otro",
]
